import React, {useState, useEffect} from "react"
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import WebFont from 'webfontloader';
import mockup from "../../images/mockup.png"
import coloursMockup from "../../images/colours-mockup.png"
import styleMockup from "../../images/style-mockup.png"
import typographyMockup from "../../images/typography-mockup.png"
// import NavBar from '../WidgetComponents/NavBar'
// import MenuButton from '../WidgetComponents/MenuButton'
import Loader from '../WidgetComponents/Loader'
// import {ReactTypeformEmbed} from 'react-typeform-embed';
import * as typeformEmbed from '@typeform/embed'
import {navigate} from "gatsby"

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Work Sans',
    fontSize: '60px',
    fontWeight: 'bolder',
    textAlign: 'center',
    lineHeight: 1,
    padding: '0px 10px 20px',
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px',
      paddingBottom: '10px',
      fontSize: '12vw'
    }
  },
  subtitle: {
    fontFamily: 'Work Sans',
    fontWeight: 'normal',
    fontSize: '30px',
    paddingBottom: '30px',
    textAlign: 'center',
    lineHeight: 1.2,
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '25px',
      paddingBottom: '20px'
    }
  },
  secondaryTitle: {
    fontFamily: 'Work Sans',
    fontSize: '50px',
    fontWeight: 'bolder',
    textAlign: 'center',
    lineHeight: 1,
    padding: '15px 50px',
    color: '#262626',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px',
      padding: '45px 10px',
      paddingBottom: '10px',
      fontSize: '12vw'
    }
  },
  secondarySubtitle: {
    color: '#262626',
    fontFamily: 'Work Sans',
    fontWeight: 'normal',
    fontSize: '25px',
    padding: '0px 40px',
    textAlign: 'center',
    lineHeight: 1.2,
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '0px 20px',
      fontSize: '25px',
      paddingBottom: '20px'
    }
  },
  textContainer: {
    backgroundColor: '',
    // height: '50vh',
    // margin: '0px 50px 50px 50px',
    width: '40vw',
    paddingRight: '10px',
    // margin: '50px 50px 100px 50px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '80vw',
      // paddingRight: '0px',
      margin: '0px',
      padding: '0px 0px 10vw'
    }
  },
  mockupContainer: {
    backgroundColor: '',
    width: '50vw',
    minHeight: '80vh',
    margin: '-3vw',
    marginTop: '0px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      minHeight: '70vh'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      margin: '0vw',
      marginTop: '50px',
      minHeight: '40vh',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '0vw',
      padding: '0px'
    }
  },
  secondaryMockupContainer: {
    backgroundColor: '',
    width: '50vw',
    margin: '-3vw',
    marginTop: '0px',
    padding: '40px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      margin: '0vw',
      marginTop: '50px',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '0vw',
      padding: '0px'
    }
  },
  mockup: {
    backgroundColor: '',
    width: '50vw',
    margin: '0vw 0vw 0vw 0vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      // marginTop: '30px',
      margin: '0px 0px 0px 0px',
      padding: '0px'
    }
  },
  secondaryMockup: {
    backgroundColor: '',
    width: '40vw',
    margin: '0vw 0vw 0vw 0vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '60vw',
      paddingBottom: '25px'
      // margin: '50px 50px 0px 50px',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      // marginTop: '30px',
      margin: '0px 0px 0px 0px',
      padding: '0px'
    }
  },
  secondaryTextContainer: {
    width: '40vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      width: '80vw'
    }
  },
  secondaryButton: {
    margin: '25px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  navBar: {
    padding: '20px 35px 0px 40px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '10px 15px 0px 20px'
    }
  }
}));

let fontsToLoad = [
  'Work Sans',
  'Lobster',
  'Dancing Script',
  'Raleway',
  'Poppins',
  'Playfair Display',
  'Bebas Neue',
  'Bangers',
  'Comfortaa',
  'Abril Fatface',
  'Righteous',
  'Fredoka One',
  'Pacifico',
  'Courgette'
]

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: fontsToLoad.map(font => font + ':lighter,normal,bold,bolder')
    }
  });
}

function NewIntroPage(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  // const [mockupLoaded, setMockupLoaded] = useState(false)
  const [mockupAnimatedIn, setMockupAnimatedIn] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        setTimeout(event => setMockupAnimatedIn(true), 200)
      }
      setIsLoaded(true)
    }
  }, [isLoaded]);

  function openForm() {
    typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
      // mode: 'drawer_left',
      autoOpen: true,
      // autoClose: 3,
      hideScrollbars: true,
      onSubmit: function() {
        console.log('Typeform successfully submitted')
      }
    })
  }

  const classes = useStyles();

  // <MenuButton/>

  let NavBar = (<Grid className={classes.navBar} container="container" direction="row" justify="space-between" alignItems="center">
    <Grid item="item">
      <Grid container="container" direction="row" justify="center" alignItems="center">
        <Typography style={{
            fontFamily: 'Lobster',
            fontSize: '23px',
            color: '#242424'
          }}>QuickStyle</Typography>
      </Grid>
    </Grid>
    <Button onClick={event => navigate('/app/signin')} color='secondary' size='large'>Sign In</Button>
  </Grid>)

  let page = (<Grid container="container" direction="column" justify="center" alignItems="center" style={{
      backgroundColor: '#f7faff',
      minHeight: '100vh',
      padding: '0px',
      margin: '0px'
    }}>
    {NavBar}
    <Grid container="container" direction="column" justify="center" alignItems="center">
      <Grid container="container" direction="row" justify="center" alignItems="center" style={{
          backgroundColor: '',
          width: '90vw',
          paddingTop: '20px'
        }}>
        <Grid className={classes.mockupContainer} item="item">
          <Fade in={mockupAnimatedIn}>
            <img className={classes.mockup} id='mockup' alt='Product Mockup' src={mockup}/>
          </Fade>
        </Grid>
        <Grow in="in">
          <Grid item="item">
            <Grid className={classes.textContainer} container="container" direction="column" justify="flex-start" alignItems="center">
              <Typography className={classes.title}>Make your brand stand out, fast</Typography>
              <Typography className={classes.subtitle}>Create an awesome brand style guide in seconds.</Typography>
              <Button onClick={event => openForm()} variant='contained' color='secondary' size='large'>Get Early Access</Button>
            </Grid>
          </Grid>
        </Grow>
      </Grid>
      <Grow in="in">
        <div>
          <Grid container="container" direction="column" justify="center" alignItems="center" style={{
              // backgroundColor: '',
              width: '90vw',
              padding: '60px 20px'
            }}>
            <Grid container="container" direction="row-reverse" justify="space-evenly" alignItems="center" style={{
                padding: '30px 0px',
                backgroundColor: ''
              }}>
              <img className={classes.secondaryMockup} id='mockup' alt='Product Mockup' src={typographyMockup}/>
              <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center">
                <Typography className={classes.secondaryTitle}>Start with your name {<span role="img" aria-label="rocket">✍️</span>}</Typography>
                <Typography className={classes.secondarySubtitle}>Choose from a selection of popular fonts or add your own.</Typography>
                <Button className={classes.secondaryButton} onClick={event => openForm()} variant='outlined' color='secondary' size=''>Try QuickStyle</Button>
              </Grid>
            </Grid>
            <Grid container="container" direction="row" justify="space-evenly" alignItems="center" style={{
                padding: '30px 0px',
                backgroundColor: ''
              }}>
              <img className={classes.secondaryMockup} id='mockup' alt='Product Mockup' src={coloursMockup}/>
              <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center">
                <Typography className={classes.secondaryTitle}>Throw in some colour {<span role="img" aria-label="rocket">🎨</span>}</Typography>
                <Typography className={classes.secondarySubtitle}>Pick a palette and customize it to match your brand.</Typography>
                <Button className={classes.secondaryButton} onClick={event => openForm()} variant='outlined' color='secondary' size=''>Try QuickStyle</Button>
              </Grid>
            </Grid>
            <Grid container="container" direction="row-reverse" justify="space-evenly" alignItems="center" style={{
                padding: '30px 0px'
              }}>
              <img className={classes.secondaryMockup} id='mockup' alt='Product Mockup' src={styleMockup}/>
              <Grid className={classes.secondaryTextContainer} container="container" direction="column" justify="center" alignItems="center">
                <Typography className={classes.secondaryTitle}>Share your style guide {<span role="img" aria-label="rocket">👌</span>}</Typography>
                <Typography className={classes.secondarySubtitle}>Send your brand to designers, developers, or your best friend.</Typography>
                <Button className={classes.secondaryButton} onClick={event => openForm()} variant='outlined' color='secondary' size=''>Try QuickStyle</Button>
              </Grid>
            </Grid>
            <Grid container="container" direction="row-reverse" justify="space-evenly" alignItems="center" style={{
                padding: '50px 0px 30px 0px'
              }}>
              <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                  padding: '5vw 10px 8vw 10px'
                }}>
                <Typography className={classes.title}>Join before the public launch</Typography>
                <Typography className={classes.subtitle}>Try QuickStyle early and help us choose the best features.</Typography>
                <Button onClick={event => openForm()} variant='contained' color='secondary' size='large' style={{
                    margin: '0px'
                  }}>Get Early Access</Button>
              </Grid>
            </Grid>
            <Button color='inherit' size='large' onClick={event => window.open('https://solomongomez.com')} style={{
                // margin: '10px',
                marginBottom: '-40px',
                color: 'grey'
              }}>Made by Solomon Gomez</Button>
          </Grid>
        </div>
      </Grow>
    </Grid>
  </Grid>)

  return (
    (isLoaded)
    ? page
    : <Loader/>)
}

export default NewIntroPage

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
