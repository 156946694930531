import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Img from "gatsby-image";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import WorkSans from 'typeface-work-sans';
// import Chip from '@material-ui/core/Chip';

// import {navigate} from "gatsby"

// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {useTheme} from '@material-ui/core/styles';

// import Layout from "../components/layout";

const useStyles = makeStyles(theme => ({
  card: {
    // backgroundColor: '#f50057',
    backgroundColor: '#ffedf4',
    width: '300px',
    height: '370px',
    margin: '2.5vw',
    padding: '5px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      backgroundColor: '#ffedf4',
      boxShadow: '0 4px 12px 8px rgba(0,0,0,0.08)',
      cursor: 'pointer',
      // transform: 'scale(1.01)'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '20px',
      height: ''
    }
  },
  heading: {
    // color: '#212121',
    padding: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: 50,
    lineHeight: 1,
    // color: 'white',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: 30,
      // paddingBottom: '20px'
    }
  },
  detail: {
    padding: '8px'
  },
  chip: {
    margin: '5px'
  },
  button: {
    // margin: '8px 0px 0px'
  }
}));

export default function ExampleCard(props) {
  // const theme = useTheme();

  // const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const handleClick = () => {
    // navigate("/page", {
    //   state: {
    //     company: props.drinkData
    //   }
    // })
    props.setOpenCompany(props.drinkData)
    props.openCompanyDialog()
  }

  // let drinkImageUrl = `https://logo.clearbit.com/${props.drinkData.domain}?size=80`

  return (<Card className={classes.card} variant='outlined' elevation='0' onClick={handleClick}>
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        // backgroundColor: '',
        height: '100%',
        padding: '40px 20px'
      }}>
      <Typography className={classes.heading} align='center' utterBottom="utterBottom" variant="h5" component="h2">
        New Style Guide
      </Typography>
      <Button variant='contained' color='secondary' style={{
          margin: '15px'
        }}>Start Now</Button>
    </Grid>
  </Card>);
}
