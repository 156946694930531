import React, {useState} from "react"
import Layout from "../components/layout"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import Test from "../components/PageComponents/TestPage.js"
import SmallPage from "../components/PageComponents/SmallPage.js"
// import IntroPage from "../components/PageComponents/IntroPage.js"
import NewIntroPage from "../components/PageComponents/NewIntroPage.js"
import EnterNamePage from "../components/PageComponents/EnterNamePage.js"
import SelectFontTypePage from "../components/PageComponents/SelectFontTypePage.js"
import SelectFontPage from "../components/PageComponents/SelectFontPage.js"
import SearchFontPage from "../components/PageComponents/SearchFontPage.js"
import IntroColorPage from "../components/PageComponents/IntroColorPage.js"
import SelectPalettePage from "../components/PageComponents/SelectPalettePage.js"
import EditPalettePage from "../components/PageComponents/EditPalettePage.js"
import CompletePage from "../components/PageComponents/CompletePage.js"
import MobileCompletePage from "../components/PageComponents/MobileCompletePage.js"
import SEO from "../components/seo"
import WebFont from 'webfontloader';

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function IndexPage() {
  const theme = useTheme();
  const [page, setPage] = useState('NewIntroPage')
  const [selectedBrandName, setSelectedBrandName] = useState(null)
  const [selectedFontType, setSelectedFontType] = useState(null)
  const [selectedFont, setSelectedFont] = useState(null)
  const [selectedPalette, setSelectedPalette] = useState(['#EDEDED'])
  const [finishedGenerating, setFinishedGenerating] = useState(false)

  let renderedPage

  let isSmDown = (useMediaQuery(theme.breakpoints.down('sm')))
  let isXsDown = (useMediaQuery(theme.breakpoints.down('xs')))

  if (page === 'Test') {
    renderedPage = <Test setPage={page => setPage(page)}/>
  } else if (page === 'NewIntroPage') {
    renderedPage = <NewIntroPage setPage={page => setPage(page)}/>
  } else if (isSmDown) {
    if (page === 'CompletePage' && isXsDown) {
      renderedPage = <MobileCompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'CompletePage' && !isXsDown) {
      renderedPage = <CompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    } else {
      renderedPage = <SmallPage selectedPalette={["#e8ddcb", "#cdb380", "#036564", "#033649", "#031634"]} selectedBrandName='QuickStyle' selectedFont='Lobster' setPage={page => setPage(page)}/>
    }
  } else {
    if (page === 'NewIntroPage') {
      renderedPage = <NewIntroPage setPage={page => setPage(page)}/>
    } else if (page === 'EnterNamePage') {
      renderedPage = <EnterNamePage setSelectedBrandName={setSelectedBrandName} setPage={page => setPage(page)}/>
    } else if (page === 'SelectFontTypePage') {
      renderedPage = <SelectFontTypePage selectedBrandName={selectedBrandName} setSelectedFontType={setSelectedFontType} setPage={page => setPage(page)}/>
    } else if (page === 'SelectFontPage') {
      renderedPage = <SelectFontPage selectedBrandName={selectedBrandName} selectedFontType={selectedFontType} setSelectedFont={setSelectedFont} setPage={page => setPage(page)}/>
    } else if (page === 'SearchFontPage') {
      renderedPage = <SearchFontPage setSelectedFont={setSelectedFont} setPage={page => setPage(page)}/>
    } else if (page === 'IntroColorPage') {
      renderedPage = <IntroColorPage selectedBrandName={selectedBrandName} selectedFont={selectedFont} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'SelectPalettePage') {
      renderedPage = <SelectPalettePage selectedBrandName={selectedBrandName} selectedFont={selectedFont} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'EditPalettePage') {
      renderedPage = <EditPalettePage selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'CompletePage') {
      renderedPage = <CompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    }
  }

  return (<Layout>
    <SEO title='Early Access'/> {renderedPage}
  </Layout>)
}

export default IndexPage
