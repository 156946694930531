import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox(props) {
  const handleChange = (event) => {
    props.setFont(event.target.textContent)
  };

  return (<Autocomplete id="combo-box-demo" options={props.allFonts} getOptionLabel={(option) => option.family} style={{
      width: 300
    }} renderInput={(params) => <TextField {...params} label="Select a Font" variant="outlined"/>} onInputChange={handleChange}/>);
}
