import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
// import { navigate } from "gatsby"
// import {DrinkData} from '../../data/DrinkData.js'
// import DrinkCard from '../../components/WidgetComponents/DrinkCard.js'
import NewCard from '../../components/WidgetComponents/NewCard.js'
import DemoCard from '../../components/WidgetComponents/DemoCard.js'

let fontsToLoad = ['Work Sans']

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: fontsToLoad.map(font => font + ':lighter,normal,bold,bolder')
    }
  });
}

function Home(props) {

  // const cardCatalog = DrinkData.map((drink) => <Grow in="in">
  //   <Grid item="item"><DrinkCard drinkData={drink}/></Grid>
  // </Grow>)

  return (<Grid container="container" direction='column' justify="center" alignItems="center" style={{
      // paddingTop: '80px',
      backgroundColor: '#FAFAFA',
      height: '100vh'
    }}>
    <Grid item="item">
      <Typography style={{
          fontFamily: 'Work Sans',
          fontSize: '30px',
          fontWeight: 'bold'
        }}>My Brands</Typography>
    </Grid>
    <Grid item="item">
      <Grid container="container" direction='row' justify="center" alignItems="center" style={{
          paddingTop: '20px'
        }}>
        <NewCard/><DemoCard/><DemoCard/>
      </Grid>
    </Grid>
  </Grid>)
}

export default Home

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
