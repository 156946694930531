import React from "react"
import Loader from '../WidgetComponents/Loader'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

function EditPalettePage(props) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarColor, setSnackbarColor] = React.useState(null);

  if (typeof window !== `undefined` && !props.finishedGenerating) {
    setTimeout(event => props.setFinishedGenerating(true), 3000)
  }

  const formattedFont = props
    .selectedFont
    .split(' ')
    .join('+')
  const fontURL = 'https://fonts.google.com/specimen/' + formattedFont

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  function copyHex(event, paletteColor) {
    navigator
      .clipboard
      .writeText(paletteColor)
      .then(function() {
        setSnackbarColor(paletteColor)
        // console.log(paletteColor)
        setSnackbarOpen(true);
      }, function() {
        /* clipboard write failed */
      });
  }

  let title = (<Grid item="item">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        padding: '50px 10px 40px'
      }}>
      <Typography style={{
          fontFamily: 'Work Sans',
          fontWeight: '200',
          fontSize: '18px',
          color: 'grey'
          // textAlign: 'center'
        }}>My Brand</Typography>
      <Typography style={{
          fontFamily: props.selectedFont,
          fontWeight: 'bold',
          fontSize: '50px',
          // padding: '10px',
          // textAlign: 'center'
          // paddingTop: '40px'
        }}>{props.selectedBrandName}</Typography>
    </Grid>
  </Grid>)

  let leftColumn = (<Grid item="item">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        // minHeight: '70vh',
        width: '100vw'
      }}>
      <Grid item="item">
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            paddingBottom: '30px'
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: '200',
              fontSize: '20px',
              color: 'grey'
            }}>Typography</Typography>
          <Typography style={{
              fontFamily: props.selectedFont,
              fontWeight: 'bold',
              fontSize: '90px',
              padding: '0px 10px 0px',
              // marginTop: '-10px'
              // paddingTop: '40px'
            }}>Aa</Typography>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: '400',
              // color: 'grey',
              fontSize: '20px',
              marginTop: '-10px'
            }}>{props.selectedFont}</Typography>
          <Button onClick={event => window.open(fontURL)} variant='outlined' color='inherit' size='small' style={{
              color: 'grey',
              marginTop: '10px'
            }}>Get Font</Button>
        </Grid>
      </Grid>
    </Grid>
  </Grid>)

  let rightColumn = (<Grid item="item">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        // width: '35vw',
        // minHeight: '70vh',
        padding: '10px'
      }}>
      <Typography style={{
          fontFamily: 'Work Sans',
          fontWeight: '200',
          fontSize: '20px',
          padding: '20px',
          color: 'grey'
        }}>Colour Palette</Typography>
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          // width: '350px',
          // padding: '0px',
          // marginBottom: '25px'
        }}>{
          props
            .selectedPalette
            .map(paletteColor => <Grid container="container" direction="row" justify="center" alignItems="center" style={{
                padding: '10px',
                backgroundColor: ''
              }}>
              <div style={{
                  backgroundColor: paletteColor,
                  marginRight: '10px',
                  width: '70px',
                  height: '70px',
                  borderRadius: '100px',
                  border: '0.5px solid #f7f7f7'
                }}></div>
              <Grid item="item">
                <Grid container="container" direction="column" justify="center" alignItems="center">
                  <h1 style={{
                      fontFamily: 'Work Sans',
                      fontWeight: '200',
                      fontSize: '20px',
                      padding: '0px',
                      margin: '0px',
                      color: paletteColor,
                      // '&:hover' : {
                      //   color: paletteColor
                      // }
                    }}>{paletteColor}</h1>
                  <Button onClick={event => copyHex(event, paletteColor)} variant='outlined' color='inherit' size='small' style={{
                      color: paletteColor,
                      margin: '5px'
                    }}>Copy Hex</Button>
                </Grid>
              </Grid>
            </Grid>)
        }
      </Grid>
    </Grid>
  </Grid>)

  let snackbar = (<Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleClose}>
    <Alert onClose={handleClose} style={{
        backgroundColor: snackbarColor
      }}>
      Success, hex code copied!
    </Alert>
  </Snackbar>)

  return (
    props.finishedGenerating
    ? <Grow in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          minHeight: '100vh',
          // width: '100vw',
          padding: '0px 20px 50px'
        }}>
        {title}
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            // height: '80vh'
          }}>
          {leftColumn}
          {rightColumn}
        </Grid>
        {snackbar}
      </Grid>
    </Grow>
    : <Loader message='Generating Your Awesome New Style Guide'/>)
}

// <NavButtons backPage='EditPalettePage' nextPage='EditPalettePage' setPage={page => props.setPage(page)} selectionMade={true}/>

export default EditPalettePage
