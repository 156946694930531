import React from "react"
// import Loader from '../WidgetComponents/Loader'
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button'
// import Snackbar from '@material-ui/core/Snackbar'
// import MuiAlert from '@material-ui/lab/Alert';

function Test(props) {
  return <div>
    <Button onClick={event => props.setPage('NewIntroPage')}>GO</Button>
  </div>
}
export default Test
