import React, {useState} from "react"
import Combobox from "../WidgetComponents/Combobox"
import NavButtons from "../WidgetComponents/NavButtons"
import Grid from '@material-ui/core/Grid';

let allGoogleFonts

function SearchFontPage(props) {
  const [font, setFont] = useState('Work Sans')
  const [loaded, setLoaded] = useState(false)

  fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyADD6xPioonVhG9x-tNE0u7-2RvuV3-Jws')
    .then(function(response) {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        return;
      }
      response
        .json()
        .then(function(data) {
          allGoogleFonts = data.items
          setLoaded(true)
        });
    })
    .catch(function(err) {
      console.log('Fetch Error :-S', err);
    });

  return (<div>
    {
      loaded && <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
            backgroundColor: '',
            margin: 'auto'
          }}>
          <h1 style={{
              fontFamily: 'Work Sans',
              fontWeight: '200',
              fontSize: '20px'
            }}>Pick Your Main Font</h1>
          <h1 style={{
              fontFamily: font,
              fontSize: '60px'
            }}>My Brand in {font}</h1>
          <Combobox allFonts={allGoogleFonts} setFont={font => setFont(font)}/>
          <NavButtons backPage='SelectFontPage' nextPage='SelectFontTypePage' setPage={page => props.setPage(page)}/>
        </Grid>
    }
  </div>)
}

export default SearchFontPage
