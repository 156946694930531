import React, {useState} from "react"
import NavButtons from "../WidgetComponents/NavButtons"
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import WebFont from 'webfontloader';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    }
  },
  selectedCard: {
    backgroundColor: '#deffeb',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    }
  }
}));

function SelectFontTypePage(props) {
  const [selection, setSelection] = useState(null)
  const [font, setFont] = useState('Work Sans')
  const [fontExamples, setFontExamples] = useState(null)

  const classes = useStyles();

  let fontTypes = [
    {
      type: 'Serif',
      category: 'serif',
      example: 'PT Serif'
    }, {
      type: 'Sans Serif',
      category: 'sans-serif',
      example: 'Open Sans'
    }, {
      type: 'Display',
      category: 'display',
      example: 'Lobster'
    }, {
      type: 'Handwriting',
      category: 'handwriting',
      example: 'Dancing Script'
    }
  ]

  let exampleFonts = fontTypes.map(font => font.example)

  exampleFonts.push('Work Sans', 'PT Serif', 'Arvo', 'Bree Serif', 'Raleway', 'Oswald', 'Poppins', 'Lobster', 'Righteous', 'Bangers', 'Pacifico', 'Kaushan Script', 'Great Vibes')

  if (typeof window !== `undefined`) {
    WebFont.load({
      google: {
        families: exampleFonts.map(font => font + ':lighter,normal,bold')
      }
    });
  }

  function selectFontType(font) {
    setFont(font)
    setSelection(font.category)
    props.setSelectedFontType(font.category)
    if (font.category === 'serif') {
      setFontExamples(['PT Serif', 'Arvo', 'Bree Serif'])
    } else if (font.category === 'sans-serif') {
      setFontExamples(['Raleway', 'Oswald', 'Poppins'])
    } else if (font.category === 'display') {
      setFontExamples(['Lobster', 'Righteous', 'Bangers'])
    } else if (font.category === 'handwriting') {
      setFontExamples(['Pacifico', 'Kaushan Script', 'Great Vibes'])
    }
  }

  let fontList = (fontTypes.map(font => <Card className={font.category === selection
      ? classes.selectedCard
      : classes.card} variant='outlined' elevation='0' onClick={event => selectFontType(font)}>
    <h1 style={{
        fontFamily: font.example,
        fontWeight: font.type === 'Display'
          ? '100'
          : '900',
        margin: '0'
      }}>{font.type}</h1>
  </Card>))

  let typeExplanation

  if (font.category === 'serif') {
    typeExplanation = "Serif fonts have a decorative stroke that extends from each letter. You can use this to give your brand a more traditional and professional look."
  } else if (font.category === 'sans-serif') {
    typeExplanation = "Sans serif fonts don't have a serif. Makes sense. This is a good choice if you want a more modern and minimalist look for your brand."
  } else if (font.category === 'display') {
    typeExplanation = "Display fonts are a bit more interesting. Choose one of these fonts if you want your branding to be easily recognizable. It's best used for titles."
  } else if (font.category === 'handwriting') {
    typeExplanation = "You probably guessed it. These fonts are in a handwritten style. You should go for this if you want your brand to look more personal. This is also best for titles."
  }

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
          backgroundColor: '',
          margin: 'auto'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '28px',
            paddingBottom: '25px'
          }}>Pick a Font Type</Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center">
          {fontList}
        </Grid>
        <Grow in="in">
          <Grid container="container" direction="column" justify="center" alignItems="center">
            {
              fontExamples && <Typography style={{
                    fontFamily: 'Work Sans',
                    fontSize: '20px',
                    textAlign: 'center',
                    paddingTop: '40px'
                  }}>Fonts Like</Typography>
            }
            <Grid container="container" direction="row" justify="center" alignItems="center">
              {
                fontExamples && fontExamples.map(text => <Typography style={{
                    textAlign: 'center',
                    padding: '15px 20px 0px',
                    fontWeight: 'bolder',
                    fontFamily: text,
                    fontSize: '35px'
                  }}>{text}</Typography>)
              }
            </Grid>
            {
              fontExamples && <Typography style={{
                    // minHeight: '120px',
                    maxWidth: '80%',
                    fontFamily: 'Work Sans',
                    fontSize: '25px',
                    padding: '40px 0px 20px',
                    textAlign: 'center'
                  }}>{
                    font === 'Work Sans'
                      ? props.selectedBrandName
                      : typeExplanation
                  }</Typography>
            }
          </Grid>
        </Grow>
        <NavButtons backPage='EnterNamePage' nextPage='SelectFontPage' setPage={page => props.setPage(page)} selectionMade={selection !== null}/>
      </Grid>
    </Grid>
  </Grow>)
}

export default SelectFontTypePage
